<template>
  <MemProfCreationWrapper current-step="Krok 2 z 2: Dodatkowe sekcje">
    <template #step-indicators>
      <span class="step-indicator active"></span>
      <span class="step-indicator active"></span>
    </template>

    <div v-if="store.memorialProfile.sections.length">
      <div v-for="(section, index) in store.memorialProfile.sections" :key="index">
        <component
            :is="categorySectionComponents[section.key]"
            :category="section"
            :sections="store.memorialProfile.sections"
            @section-updated="saveChanges"
        />
      </div>
    </div>

    <div v-if="store.memorialProfile.sections.length < allSectionsCount" class="step-content">
      <MemProfCategorySelector
          v-model:selectedCategoryProp="localSelectedCategory"
          :sections="store.memorialProfile.sections"
          @section-added="saveChanges"
      />
    </div>
  </MemProfCreationWrapper>
</template>

<script>

import { markRaw, shallowRef } from 'vue';
import { useMemorialProfileStore } from '@/stores/memorial-profile';
import MemProfCreationWrapper from "@/components/MemorialProfileCreationForm/MemProfCreationWrapper.vue";
import MemProfCategorySelector from "@/components/MemorialProfileCreationForm/MemProfCategorySelector.vue";
import EducationCategory
  from "@/components/MemorialProfileCreationForm/MemorialProfileCreationCategories/EducationCategory.vue";
import WorkCategory from "@/components/MemorialProfileCreationForm/MemorialProfileCreationCategories/WorkCategory.vue";
import FamilyCategory
  from "@/components/MemorialProfileCreationForm/MemorialProfileCreationCategories/FamilyCategory.vue";
import InterestsCategory
  from "@/components/MemorialProfileCreationForm/MemorialProfileCreationCategories/InterestsCategory.vue";
import AchievementsCategory
  from "@/components/MemorialProfileCreationForm/MemorialProfileCreationCategories/AchievementsCategory.vue";
import AdditionalDescriptionCategory
  from "@/components/MemorialProfileCreationForm/MemorialProfileCreationCategories/AdditionalDescriptionCategory.vue";
import ImportantEventCategory
  from "@/components/MemorialProfileCreationForm/MemorialProfileCreationCategories/ImportantEventCategory.vue";
import GalleryCategory
  from "@/components/MemorialProfileCreationForm/MemorialProfileCreationCategories/GalleryCategory.vue";

export default {
  name: 'MemProfCreationStep3',
  components: {
    MemProfCategorySelector,
    MemProfCreationWrapper,
    EducationCategory,
    WorkCategory,
    FamilyCategory,
    InterestsCategory,
    AchievementsCategory,
    ImportantEventCategory,
    AdditionalDescriptionCategory,
    GalleryCategory,
  },
  emits: [
    'profile-updated',
  ],
  setup(_, { emit }) {
    const store = useMemorialProfileStore();
    const allSectionsCount = 8;
    const localSelectedCategory = shallowRef('');

    const saveChanges = () => {
      emit('profile-updated');
    };

    return {
      saveChanges,
      localSelectedCategory,
      allSectionsCount,
      store,
    };
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      categorySectionComponents: {
        education: markRaw(EducationCategory),
        work: markRaw(WorkCategory),
        interests: markRaw(InterestsCategory),
        achievements: markRaw(AchievementsCategory),
        important_events: markRaw(ImportantEventCategory),
        additional_description: markRaw(AdditionalDescriptionCategory),
        family: markRaw(FamilyCategory),
        gallery: markRaw(GalleryCategory),
      },
    };
  },
};
</script>

<style scoped>
</style>
